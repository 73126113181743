import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Store from "@/store";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: Store.getters["domain/getLegalEntity"].colors,
    },
  },
});
