import { axiosClient } from "../axios";

export function getRoleCandidacyByUser(userId) {
  return axiosClient.get(`role-candidacies/${userId}`);
}

export function enableTests(id) {
  return axiosClient.post(`role-candidacies/${id}/enable-test`);
}

export function insertRoleCandidacy(data) {
  return axiosClient.post(`role-candidacies/insert`, data);
}

export function deleteRoleCandidacy(roleId) {
  return axiosClient.delete(`role-candidacies/${roleId}/delete`);
}
