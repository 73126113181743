<template>
  <v-snackbar
    v-model="snackbar.active"
    :timeout="snackbar.timeout"
    :color="snackbar.color"
  >
    {{ getSnackbarMessage }}
    <template #action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar.active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TheSnackbar",
  computed: {
    ...mapState(["snackbar"]),
    getSnackbarMessage() {
      return this.snackbar.code
        ? `${this.snackbar.code} - ${this.snackbar.message}`
        : `${this.snackbar.message}`;
    },
  },
};
</script>
