<template>
  <v-btn v-bind="{ ...$attrs, ...baseAttrs }" v-on="$listeners">
    <slot>Back</slot>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButtonBack",
  inheritAttrs: false,
  computed: {
    baseAttrs() {
      return {
        color: "secondary",
        plain: true,
      };
    },
  },
};
</script>
