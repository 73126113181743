import { axiosClient } from "../axios";

export function getStaffList() {
  return axiosClient.get("admins");
}

export function editStaffUser(data) {
  return axiosClient.post(`admins/${data.id}/update`, data);
}

export function addStaffUser(data) {
  return axiosClient.post(`admins/create`, data);
}

export function deleteStaffUser(id) {
  return axiosClient.delete(`admins/${id}/delete`);
}

export function resetStaffPassword(id) {
  return axiosClient.post(`admins/${id}/reset-password`);
}
