import { axiosClient } from "../axios";

export function registerUser(data, positionId, roleId) {
  return axiosClient.post(
    `registration/create-user?position=${positionId}&role=${roleId}`,
    data
  );
}

export function validateEmail(token) {
  return axiosClient.post(`registration/validate?token=${token}`);
}

export function renewToken(token) {
  return axiosClient.post(`registration/renew-token?token=${token}`);
}

export function finalizeRegistration(userId, data) {
  return axiosClient.post(`registration/users/${userId}/finalize`, data);
}
