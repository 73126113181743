import { ExportApi } from "@/api";
import { base64ToBlob, downloadFile } from "@/utils/file";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function exportQuizzes() {
  const response = await ExportApi.exportQuizzes();
  const { file, name } = response.data;
  let url = URL.createObjectURL(base64ToBlob(file, "application/octet-stream"));
  downloadFile(url, name);
  showSuccessSnackbar(response.status, "Quizzes exported successfully");
}
