export function file2Base64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function base64ToBlob(base64, type) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: type });
}

export function downloadFile(url, name) {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  link.click();
  link.remove();
}

export function downloadFileFromUrl(url, name) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      downloadFile(url, name);
    });
}

export function downloadBlob(response) {
  const { data: blob, headers } = response;
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(headers["content-disposition"]);
  let filename = "blob_file";
  if (matches != null && matches[1]) {
    filename = matches[1].replace(/['"]/g, "");
  }
  const url = URL.createObjectURL(blob);
  downloadFile(url, filename);
}

export function readTextFile(file) {
  return new Promise((resolve, reject) => {
    let contents = "";
    const reader = new FileReader();
    reader.onloadend = function (e) {
      contents = e.target.result;
      resolve(contents);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
  });
}
