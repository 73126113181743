import { Enum } from "../base/enum";

const skillEnum = new Enum(
  [
    "abkhaz",
    "afar",
    "afrikaans",
    "akan",
    "albanian",
    "amharic",
    "arabic",
    "aragonese",
    "armenian",
    "assamese",
    "aconstic",
    "avestan",
    "aymara",
    "azerbaijani",
    "bambara",
    "bashkir",
    "basque",
    "belarusian",
    "bengali",
    "bihari",
    "bislama",
    "bosnian",
    "breton",
    "bulgarian",
    "burmese",
    "catalan",
    "chamorro",
    "chechen",
    "chichewa",
    "chinese",
    "chuvash",
    "cornish",
    "corsican",
    "cree",
    "croatian",
    "czech",
    "danish",
    "divehi",
    "dutch",
    "dzongkha",
    "english",
    "esperanto",
    "estonian",
    "ewe",
    "faroese",
    "fijian",
    "finnish",
    "french",
    "fula",
    "galician",
    "georgian",
    "german",
    "greek",
    "guaraní",
    "gujarati",
    "haitian",
    "hausa",
    "hebrew",
    "herero",
    "hindi",
    "hiri motu",
    "hungarian",
    "interlingua",
    "indonesian",
    "interlingue",
    "irish",
    "igbo",
    "inupiaq",
    "ido",
    "icelandic",
    "italian",
    "inuktitut",
    "japanese",
    "javanese",
    "kalaallisut",
    "kannada",
    "kanuri",
    "kashmiri",
    "kazakh",
    "khmer",
    "kikuyu",
    "kinyarwanda",
    "kyrgyz",
    "komi",
    "kongo",
    "korean",
    "kurdish",
    "kwanyama",
    "latin",
    "luxembourgish",
    "ganda",
    "limburgish",
    "lingala",
    "lao",
    "lithuanian",
    "luba katanga",
    "latvian",
    "manx",
    "macedonian",
    "malagasy",
    "malay",
    "malayalam",
    "maltese",
    "māori",
    "marathi",
    "marshallese",
    "mongolian",
    "nauruan",
    "navajo",
    "northern ndebele",
    "nepali",
    "ndonga",
    "norwegian bokmål",
    "norwegian nynorsk",
    "norwegian",
    "nuosu",
    "southern ndebele",
    "occitan",
    "ojibwe",
    "old church slavonic",
    "oromo",
    "oriya",
    "ossetian",
    "pāli",
    "persian",
    "polish",
    "pashto",
    "portuguese",
    "punjabi",
    "quechua",
    "romansh",
    "kirundi",
    "romanian",
    "russian",
    "sanskrit",
    "sardinian",
    "sindhi",
    "northern sami",
    "samoan",
    "sango",
    "serbian",
    "scottish gaelic",
    "shona",
    "sinhalese",
    "slovak",
    "slovene",
    "somali",
    "southern sotho",
    "spanish",
    "sundanese",
    "swahili",
    "swati",
    "swedish",
    "tamil",
    "telugu",
    "tajik",
    "thai",
    "tigrinya",
    "tibetan",
    "turkmen",
    "tagalog",
    "tswana",
    "tonga",
    "turkish",
    "tsonga",
    "tatar",
    "twi",
    "tahitian",
    "uyghur",
    "ukrainian",
    "urdu",
    "uzbek",
    "venda",
    "vietnamese",
    "volapük",
    "walloon",
    "welsh",
    "wolof",
    "western frisian",
    "xhosa",
    "yiddish",
    "yoruba",
    "zhuang",
    "zulu",
  ],
  [
    10272, 17408, 17568, 320, 8722, 7552, 544, 6688, 4871, 12864, 672, 4768,
    13056, 4896, 12289, 10241, 19076, 11393, 13441, 7425, 18689, 18881, 4641,
    11905, 780, 19458, 226, 4322, 781, 14585, 21730, 17858, 18882, 4642, 22055,
    18562, 13315, 21763, 3437, 15139, 6564, 14820, 20036, 4804, 14341, 9477,
    13573, 549, 11909, 6502, 19466, 20611, 11620, 13862, 9862, 19463, 20487,
    1159, 17543, 13575, 14727, 13959, 424, 3496, 4456, 4454, 14376, 10728,
    14440, 11848, 616, 20808, 13801, 21513, 11274, 13322, 20490, 18442, 25610,
    12522, 10506, 13578, 17674, 12746, 13770, 17866, 18058, 650, 19467, 26219,
    6795, 12555, 13579, 14347, 19723, 1675, 21515, 21862, 3404, 6508, 588,
    11276, 19820, 8748, 17420, 7180, 13772, 20493, 21517, 4205, 15501, 14445,
    1485, 14765, 17869, 8456, 11309, 8270, 8494, 20706, 12846, 8750, 19022,
    8559, 18437, 11727, 19087, 17871, 13327, 4752, 7633, 13969, 13777, 19089,
    13330, 3634, 3506, 4498, 14738, 6162, 15922, 358, 434, 13586, 10610, 21874,
    12754, 19922, 498, 13970, 722, 23122, 4818, 12307, 11411, 10451, 243, 17683,
    3521, 10899, 11475, 13907, 13779, 18067, 14931, 19475, 8915, 7187, 6420,
    17748, 3636, 1844, 13461, 4373, 11733, 13686, 13058, 11734, 25125, 14583,
    3352, 17880, 249, 11929,
  ],
  [
    "Abkhaz",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Arabic",
    "Aragonese",
    "Armenian",
    "Assamese",
    "Avaric",
    "Avestan",
    "Aymara",
    "Azerbaijani",
    "Bambara",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Chamorro",
    "Chechen",
    "Chichewa",
    "Chinese",
    "Chuvash",
    "Cornish",
    "Corsican",
    "Cree",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi",
    "Dutch",
    "Dzongkha",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Faroese",
    "Fijian",
    "Finnish",
    "French",
    "Fula",
    "Galician",
    "Georgian",
    "German",
    "Greek",
    "Guaraní",
    "Gujarati",
    "Haitian",
    "Hausa",
    "Hebrew",
    "Herero",
    "Hindi",
    "Hiri_Motu",
    "Hungarian",
    "Interlingua",
    "Indonesian",
    "Interlingue",
    "Irish",
    "Igbo",
    "Inupiaq",
    "Ido",
    "Icelandic",
    "Italian",
    "Inuktitut",
    "Japanese",
    "Javanese",
    "Kalaallisut",
    "Kannada",
    "Kanuri",
    "Kashmiri",
    "Kazakh",
    "Khmer",
    "Kikuyu",
    "Kinyarwanda",
    "Kyrgyz",
    "Komi",
    "Kongo",
    "Korean",
    "Kurdish",
    "Kwanyama",
    "Latin",
    "Luxembourgish",
    "Ganda",
    "Limburgish",
    "Lingala",
    "Lao",
    "Lithuanian",
    "Luba_Katanga",
    "Latvian",
    "Manx",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Māori",
    "Marathi",
    "Marshallese",
    "Mongolian",
    "Nauruan",
    "Navajo",
    "Northern_Ndebele",
    "Nepali",
    "Ndonga",
    "Norwegian_Bokmål",
    "Norwegian_Nynorsk",
    "Norwegian",
    "Nuosu",
    "Southern_Ndebele",
    "Occitan",
    "Ojibwe",
    "Old_Church_Slavonic",
    "Oromo",
    "Oriya",
    "Ossetian",
    "Pāli",
    "Persian",
    "Polish",
    "Pashto",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romansh",
    "Kirundi",
    "Romanian",
    "Russian",
    "Sanskrit",
    "Sardinian",
    "Sindhi",
    "Northern_Sami",
    "Samoan",
    "Sango",
    "Serbian",
    "Scottish_Gaelic",
    "Shona",
    "Sinhalese",
    "Slovak",
    "Slovene",
    "Somali",
    "Southern_Sotho",
    "Spanish",
    "Sundanese",
    "Swahili",
    "Swati",
    "Swedish",
    "Tamil",
    "Telugu",
    "Tajik",
    "Thai",
    "Tigrinya",
    "Tibetan",
    "Turkmen",
    "Tagalog",
    "Tswana",
    "Tonga",
    "Turkish",
    "Tsonga",
    "Tatar",
    "Twi",
    "Tahitian",
    "Uyghur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Volapük",
    "Walloon",
    "Welsh",
    "Wolof",
    "Western_Frisian",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang",
    "Zulu",
  ]
);

const levelEnum = new Enum(
  ["a1", "a2", "b1", "b2", "c1", "c2"],
  [0, 1, 2, 3, 4, 5],
  ["A1", "A2", "B1", "B2", "C1", "C2"]
);

const englishCertificateEnum = new Enum(
  [
    "none",
    "other",
    "ielts",
    "tracktest",
    "trinity",
    "jetset",
    "lcci_efb",
    "aim-anglia",
    "cambridge",
    "toelf",
  ],
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [
    "None",
    "Other",
    "IELTS",
    "TrackTest",
    "Trinity College",
    "JETSET",
    "LCCI EFB",
    "AIM-ANGLIA",
    "Cambridge Assessment",
    "TOELF",
  ]
);

export { levelEnum, skillEnum, englishCertificateEnum };
