export default [
  {
    path: "/questions",
    name: "questions",
    component: () =>
      import(
        /* webpackChunkName: "questions" */ "@/views/test-questions/TestQuestionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/questions/create",
    name: "create-question",
    component: () =>
      import(
        /* webpackChunkName: "questions" */ "@/views/test-questions/ManageSingleTestQuestionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
    },
  },
  {
    path: "/questions/:id(\\d+)",
    name: "single-question",
    component: () =>
      import(
        /* webpackChunkName: "questions" */ "@/views/test-questions/SingleTestQuestionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/questions/:id(\\d+)/edit",
    name: "edit-question",
    component: () =>
      import(
        /* webpackChunkName: "questions" */ "@/views/test-questions/ManageSingleTestQuestionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
];
