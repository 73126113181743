export default [
  {
    path: "/registration",
    name: "registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/RegistrationView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
  {
    path: "/registration/thank-you",
    name: "registration-thank-you",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/RegistrationThankYouView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
  {
    path: "/registration/validate",
    name: "registration-validate",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/ConfirmEmailView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
  {
    path: "/registration/finalize",
    name: "registration-finalize",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "@/views/registration/RegistrationFinalizeView.vue"
      ),
    meta: { requiresAuth: true, allowedRoles: [] },
  },
];
