import { CurrentCompensationApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function insert(data) {
  const response = await CurrentCompensationApi.insert(data);
  showSuccessSnackbar(
    response.status,
    "Current Compensation inserted successfully"
  );
}

export async function update(compensationId, data) {
  const response = await CurrentCompensationApi.update(compensationId, data);
  showSuccessSnackbar(
    response.status,
    "Current Compensation updated successfully"
  );
}

export async function remove(compensationId) {
  const response = await CurrentCompensationApi.remove(compensationId);
  showSuccessSnackbar(
    response.status,
    "Current Compensation deleted successfully"
  );
}
