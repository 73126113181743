export class Enum {
  constructor(keys, codes, labels, altLabels = []) {
    this.keys = keys;
    this.codes = codes;
    this.labels = labels;
    this.altLabels = altLabels;
    this.key2idx = new Map();
    this.code2idx = new Map();
    for (let i = 0; i < keys.length; i++) {
      this.key2idx.set(keys[i], i);
      this.code2idx.set(codes[i], i);
    }
  }

  getCode(key) {
    let idx = this.key2idx.get(key);
    return idx != undefined ? this.codes[idx] : undefined;
  }

  getKey(code) {
    let idx = this.code2idx.get(code);
    return idx != undefined ? this.keys[idx] : undefined;
  }

  getLabel(code) {
    let idx = this.code2idx.get(code);
    return idx != undefined ? this.labels[idx] : undefined;
  }

  getAltLabel(code) {
    let idx = this.code2idx.get(code);
    return idx != undefined ? this.altLabels[idx] : undefined;
  }

  getCodesList() {
    return this.codes;
  }

  getLabelsList() {
    return this.labels;
  }

  getAltLabelsList() {
    return this.altLabels;
  }

  size() {
    return this.keys.length;
  }
}
