import { UserService, InternalJobPositionService } from "@/services";
import Store from "@/store";

const getDefaultState = () => {
  return {
    loading: false,
    candidate: {},
    interviews: [],
    notes: [],
    suggestedPositions: [],
    appliedPositions: [],
    ccInternalPositions: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isLoading: (state) => {
      return state.loading;
    },
    getCandidateData: (state) => {
      return state.candidate;
    },
    getCandidateInterviews: (state) => {
      return state.interviews;
    },
    getCandidateNotes: (state) => {
      return state.notes;
    },
    getCandidateSuggestedPositions: (state) => {
      return state.suggestedPositions;
    },
    getCandidateAppliedPositions: (state) => {
      return state.appliedPositions;
    },
    getCandidateCcInternalPositions: (state) => {
      return state.ccInternalPositions;
    },
  },
  mutations: {
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
    SET_CANDIDATE_DATA: (state, candidate) => {
      state.candidate = candidate;
    },
    SET_CANDIDATE_INTERVIEWS: (state, interviews) => {
      state.interviews = interviews;
    },
    SET_CANDIDATE_NOTES: (state, notes) => {
      state.notes = notes;
    },
    SET_CANDIDATE_SUGGESTED_POSITIONS: (state, suggestedPositions) => {
      state.suggestedPositions = suggestedPositions;
    },
    SET_CANDIDATE_APPLIED_POSITIONS: (state, appliedPositions) => {
      state.appliedPositions = appliedPositions;
    },
    SET_CANDIDATE_CC_INTERNAL_POSITIONS: (state, ccInternalPositions) => {
      state.ccInternalPositions = ccInternalPositions;
    },
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    fetch: async ({ commit, state }, candidateId) => {
      commit("SET_LOADING", true);

      await Store.dispatch("institutes/fetch");
      await Store.dispatch("contractualFrameworks/fetch");
      await Store.dispatch("organization/fetch");

      const isStaffUser = Store.getters["user/isStaffUser"];

      if (isStaffUser) {
        const [interviews, notes, candidate, suggestedPositions] =
          await Promise.all([
            UserService.getInterviews(candidateId),
            UserService.getNotes(candidateId),
            UserService.getFullProfile(candidateId),
            InternalJobPositionService.getByUser(candidateId),
          ]);
        commit("SET_CANDIDATE_INTERVIEWS", interviews);
        commit("SET_CANDIDATE_NOTES", notes);
        commit("SET_CANDIDATE_DATA", candidate);
        commit("SET_CANDIDATE_APPLIED_POSITIONS", candidate.candidacies);
        commit("SET_CANDIDATE_SUGGESTED_POSITIONS", suggestedPositions);
      } else {
        const candidate = await UserService.getFullProfile(candidateId);
        commit("SET_CANDIDATE_DATA", candidate);
      }

      if (isStaffUser) {
        const ccInternalPositions = await InternalJobPositionService.getByCc(
          state.candidate.competenceCenter,
          { active: true }
        );
        commit("SET_CANDIDATE_CC_INTERNAL_POSITIONS", ccInternalPositions);
      }

      commit("SET_LOADING", false);
    },
    reset: ({ commit }) => {
      commit("SET_LOADING", true);
      commit("RESET");
      commit("SET_LOADING", false);
    },
  },
};
