import { axiosClient } from "../axios";

export function getUserWorkExperiences(id) {
  return axiosClient.get(`work-experiences/${id}`);
}

export function insertSingleWorkExperience(data) {
  return axiosClient.post("work-experiences/insert", data);
}

export function insertMultipleWorkExperiences(userId, data) {
  return axiosClient.post(`work-experiences/insert/${userId}/multiple`, data);
}

export function updateSingleWorkExperience(data) {
  return axiosClient.post(`work-experiences/${data.id}/update`, data);
}

export function remove(workExpId) {
  return axiosClient.delete(`work-experiences/${workExpId}/delete`);
}
