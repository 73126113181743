import { axiosClient } from "../axios";

export function insert(data) {
  return axiosClient.post(`current-compensations/insert`, data);
}

export function update(compensationId, data) {
  return axiosClient.post(
    `current-compensations/${compensationId}/update`,
    data
  );
}

export function remove(compensationId) {
  return axiosClient.delete(`current-compensations/${compensationId}/delete`);
}
