import { Enum } from "../base/enum";

const roleEnum = new Enum(
  ["none", "other", "ba", "fe", "sa", "da", "pm", "sd", "ds"],
  [0, 1, 2, 3, 4, 5, 7, 9, 10],
  [
    "None",
    "Other",
    "Business Analyst",
    "Financial Engineer",
    "Statistical Analyst",
    "Data Analyst",
    "Project Manager",
    "Software Developer",
    "Data Scientist",
  ]
);

const seniorityEnum = new Enum(
  ["internship", "junior", "mid", "senior"],
  [0, 1, 2, 3],
  ["Internship", "Junior", "Mid", "Senior"],
  ["Intern", "Junior", "Mid", "Senior"]
);

const areaEnum = new Enum(
  [
    "none",
    "other",
    "consulting_finance_risk",
    "consulting_it",
    "consulting_operations",
    "consulting_other",
    "banking_risk_management",
    "banking_it",
    "banking_other",
    "marketing",
  ],
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [
    "None",
    "Other",
    "Consulting – Finance & Risk",
    "Consulting – IT",
    "Consulting – Operations",
    "Consulting – Other",
    "Banking – Risk Management",
    "Banking – IT",
    "Banking – Other",
    "Marketing",
  ]
);

const contractTypeEnum = new Enum(
  ["stage", "apprenticeship", "fixed-term", "permanent", "freelance"],
  [0, 1, 2, 3, 4],
  [
    "Stage",
    "Apprenticeship",
    "Fixed-term contract",
    "Permanent contract",
    "Freelance",
  ]
);

const remoteTypeEnum = new Enum(
  ["no", "hybrid", "full"],
  [0, 1, 2],
  ["On-Site", "Hybrid Remote", "Full Remote"]
);

export { roleEnum, seniorityEnum, areaEnum, contractTypeEnum, remoteTypeEnum };
