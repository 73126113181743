<template>
  <component :is="getActualLogo" v-bind="getProps"></component>
</template>

<script>
export default {
  components: {
    IasonLogo: () => import("@/components/svg/IasonLogoSvg.vue"),
    TechnesthaiLogo: () => import("@/components/svg/TechnesthaiLogoSvg.vue"),
  },
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
  },
  data: () => ({
    domain: window.location.hostname,
  }),
  computed: {
    getActualLogo() {
      switch (window.location.hostname) {
        case "careers.iasonltd.com":
        case "careers.iasonltd.local":
          return "IasonLogo";
        case "careers.technesthai.com":
          return "TechnesthaiLogo";
        default:
          return "IasonLogo";
      }
    },
    getProps() {
      return {
        width: this.width,
        height: this.height,
      };
    },
  },
};
</script>
