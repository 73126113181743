import { DashboardApi } from "@/api";

export async function getUserDashboardData(userId) {
  const { data } = await DashboardApi.getUserDashboardData(userId);
  return data;
}

export async function getManagerLatestTests(competenceCenterId, options) {
  const { data } = await DashboardApi.getManagerLatestTests(
    competenceCenterId,
    options
  );
  return data;
}

export async function getManagerLatestInterviews(competenceCenterId, options) {
  const { data } = await DashboardApi.getManagerLatestInterviews(
    competenceCenterId,
    options
  );
  return data;
}

export async function getManagerMissingInterviews(competenceCenterId, options) {
  const { data } = await DashboardApi.getManagerMissingInterviews(
    competenceCenterId,
    options
  );
  return data;
}

export async function getAdminLatestTests(options) {
  const { data } = await DashboardApi.getAdminLatestTests(options);
  return data;
}

export async function getAdminLatestInterviews(options) {
  const { data } = await DashboardApi.getAdminLatestInterviews(options);
  return data;
}

export async function getAdminMissingInterviews(options) {
  const { data } = await DashboardApi.getAdminMissingInterviews(options);
  return data;
}
