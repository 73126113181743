import Store from "@/store";
import { computed } from "vue";

export function useSidebarLink() {
  const isAdmin = computed(() => Store.getters["user/isAdmin"]);
  const isManager = computed(() => Store.getters["user/isManager"]);
  const isInterviewer = computed(() => Store.getters["user/isInterviewer"]);
  const isUser = computed(() => Store.getters["user/isUser"]);

  const adminLinkItems = computed(() => {
    return [
      {
        icon: "mdi-view-dashboard-outline",
        to: { name: "dashboard" },
        title: "Dashboard",
      },
      {
        icon: "mdi-account-school",
        to: { name: "users" },
        title: "Users",
      },
      {
        icon: "mdi-account-search-outline",
        to: { name: "search-user" },
        title: "Advanced Search",
      },
      {
        icon: "mdi-briefcase-outline",
        title: "Job Positions",
        items: [
          {
            icon: "mdi-alpha-e",
            to: { name: "external-positions" },
            title: "External",
          },
          {
            icon: "mdi-alpha-i",
            to: { name: "internal-positions" },
            title: "Internal",
          },
        ],
      },
      {
        icon: "mdi-format-list-checks",
        to: { name: "questions" },
        title: "Questions List",
      },
      {
        icon: "mdi-badge-account-horizontal-outline",
        to: { name: "staff" },
        title: "Staff",
      },
    ];
  });

  const managerLinkItems = computed(() => {
    return [
      {
        icon: "mdi-view-dashboard-outline",
        to: { name: "dashboard" },
        title: "Dashboard",
      },
      {
        icon: "mdi-account-school",
        to: { name: "users" },
        title: "Users",
      },
      {
        icon: "mdi-account-search-outline",
        to: { name: "search-user" },
        title: "Advanced Search",
      },
      {
        icon: "mdi-human-queue",
        to: { name: "users-competence-center" },
        title: "My Comp.Center",
      },
      {
        icon: "mdi-briefcase-outline",
        title: "Job Positions",
        items: [
          {
            icon: "mdi-alpha-e",
            to: { name: "external-positions" },
            title: "External",
          },
          {
            icon: "mdi-alpha-i",
            to: { name: "internal-positions" },
            title: "Internal",
          },
        ],
      },
    ];
  });

  const interviewerLinkItems = computed(() => {
    return [
      {
        icon: "mdi-view-dashboard-outline",
        to: { name: "dashboard" },
        title: "Dashboard",
      },
      {
        icon: "mdi-human-queue",
        to: { name: "users-competence-center" },
        title: "My Comp.Center",
      },
      {
        icon: "mdi-briefcase-outline",
        to: { name: "external-positions" },
        title: "Job Positions",
      },
    ];
  });

  const userLinkItems = computed(() => {
    return [
      {
        icon: "mdi-view-dashboard-outline",
        to: { name: "dashboard" },
        title: "Dashboard",
      },
      {
        icon: "mdi-account-outline",
        to: {
          name: "single-user",
          params: { id: Store.getters["user/getUserId"] },
        },
        title: "My Profile",
      },
      {
        icon: "mdi-briefcase-outline",
        to: { name: "external-positions" },
        title: "Open Positions",
      },
      {
        icon: "mdi-list-status",
        to: {
          name: "tests-user",
          params: { id: Store.getters["user/getUserId"] },
        },
        title: "My Tests",
      },
    ];
  });

  const linkItems = computed(() => {
    if (isAdmin.value) return adminLinkItems;
    if (isManager.value) return managerLinkItems;
    if (isInterviewer.value) return interviewerLinkItems;
    if (isUser.value) return userLinkItems;
    return [];
  });

  return { linkItems };
}
