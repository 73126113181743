import { UserApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";
import { base64ToBlob, downloadFile, file2Base64 } from "@/utils/file";

export async function getAll() {
  const { data } = await UserApi.getUsers();
  return data;
}

export async function getByCc(competenceCenterId) {
  const { data } = await UserApi.getUsersByCc(competenceCenterId);
  return data;
}

export async function get(id) {
  const { data } = await UserApi.getSingleUser(id);
  return data;
}

export async function insert(data) {
  const response = await UserApi.createUser(data);
  showSuccessSnackbar(response.status, "User successfully added.");
  return response.data;
}

export async function update(id, data) {
  const response = await UserApi.updateUser(id, data);
  showSuccessSnackbar(response.status, "User successfully updated.");
  return response.data;
}

export async function remove(id) {
  const response = await UserApi.deleteUser(id);
  showSuccessSnackbar(response.status, response.data.message);
  return response.data;
}

export async function resetPassword(id) {
  const response = await UserApi.resetUserPassword(id);
  showSuccessSnackbar(response.status, "User password successfully reset.");
  return response.data;
}

export async function getResumePreSignedUrl(id) {
  const { data } = await UserApi.getUserResumePreSignedUrl(id);
  return data;
}

export async function uploadResume(id, data) {
  let fileBase64 = null;

  let filename = data.name;
  await file2Base64(data).then((response) => {
    fileBase64 = response.split(",")[1];
  });

  let resumeDto = {
    file: fileBase64,
    filename: filename,
  };
  const response = await UserApi.uploadUserResume(id, resumeDto);
  showSuccessSnackbar(response.status, "User resume successfully uploaded.");
  return response.data;
}

export async function getInterviews(id) {
  const { data } = await UserApi.getUserInterviews(id);
  return data;
}

export async function getNotes(id) {
  const { data } = await UserApi.getUserNotes(id);
  return data;
}

export async function getFullProfile(id) {
  const { data } = await UserApi.getUserFullProfile(id);
  return data;
}

export async function deleteTests(id) {
  const response = await UserApi.deleteUserTests(id);
  showSuccessSnackbar(response.status, "User tests successfully reset.");
  return response.data;
}

export async function deleteGeneralTest(id) {
  const response = await UserApi.deleteUserGeneralTest(id);
  showSuccessSnackbar(response.status, "User general test successfully reset.");
  return response.data;
}

export async function deleteRoleTest(id, roleId) {
  const response = await UserApi.deleteUserSingleRoleTests(id, roleId);
  showSuccessSnackbar(
    response.status,
    "User single role tests successfully reset."
  );
  return response.data;
}

export async function updateStatus(id, data) {
  const response = await UserApi.updateUserStatus(id, data);
  showSuccessSnackbar(response.status, "User status successfully updated.");
  return response.data;
}

export async function validateEmail(id) {
  const response = await UserApi.validateUserEmail(id);
  showSuccessSnackbar(response.status, "User e-mail successfully validated.");
  return response.data;
}

export async function enable(id) {
  const response = await UserApi.enableUser(id);
  showSuccessSnackbar(response.status, response.data.message);
  return response.data;
}

export async function advancedSearch(data) {
  const { data: users } = await UserApi.advancedUserSearch(data);
  return users;
}

export async function exportSearch(data) {
  const response = await UserApi.exportUserSearch(data);
  const { file, name } = response.data;
  let url = URL.createObjectURL(base64ToBlob(file, "application/octet-stream"));
  downloadFile(url, name);
  showSuccessSnackbar(response.status, "Search successfully exported.");
}
