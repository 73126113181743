import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "internal-open-positions";

export function getAll(
  options = {
    admin: false,
    "suggested-user": false,
    active: false,
  }
) {
  const query = getQuery(options);
  return axiosClient.get(baseUrl, query);
}

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function getByUser(userId) {
  return axiosClient.get(`${baseUrl}/by-user/${userId}`);
}

export function addByUser(userId, payload) {
  return axiosClient.post(`${baseUrl}/by-user/${userId}/add`, payload);
}

export function getByCc(
  ccId,
  options = {
    active: false,
  }
) {
  const query = getQuery(options);
  return axiosClient.get(`${baseUrl}/by-cc/${ccId}`, query);
}

export function addNewPosition(payload) {
  return axiosClient.post(`${baseUrl}`, payload);
}

export function updatePosition(id, payload) {
  return axiosClient.post(`${baseUrl}/${id}/update`, payload);
}

export function suspendPosition(id) {
  return axiosClient.post(`${baseUrl}/${id}/suspend`);
}

export function closePosition(id, payload) {
  return axiosClient.post(`${baseUrl}/${id}/close`, payload);
}

export function uploadIncomingResume(id, payload) {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("surname", payload.surname);
  formData.append("notes", payload.notes);
  formData.append("file", payload.file);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axiosClient.post(`${baseUrl}/${id}/incoming-resume`, formData, {
    headers: headers,
  });
}

export function updateIncomingResume(payload) {
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("name", payload.name);
  formData.append("surname", payload.surname);
  formData.append("notes", payload.notes);

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return axiosClient.post(`${baseUrl}/incoming-resume/update`, formData, {
    headers: headers,
  });
}

export function notifyIncomingResume(id, payload) {
  return axiosClient.post(`${baseUrl}/${id}/incoming-resume/notify`, payload);
}

export function getPreSignedUrl(id) {
  return axiosClient.get(
    `${baseUrl}/incoming-resume/${id}/file/pre-signed-url`
  );
}

export function updateIncomingResumeStatus(payload) {
  return axiosClient.post(
    `${baseUrl}/incoming-resume/${payload.id}/status`,
    payload.data
  );
}

export function removeIncomingResume(id) {
  return axiosClient.delete(`${baseUrl}/incoming-resume/${id}/remove`);
}
