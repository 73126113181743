import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store/index.js";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let userRole = Store.getters["user/getUserRole"];
  let userIsAuthenticated = Store.getters["user/isAuthenticated"];
  let userRegCompleted = Store.getters["user/isRegistrationCompleted"];
  if (to.meta.requiresAuth && !userIsAuthenticated) {
    next({ name: "login", query: { from: to.fullPath } });
  } else if (to.meta.requiresRegComplete && !userRegCompleted) {
    next({ name: "registration-finalize" });
  } else if (to.meta.allowedRoles.length == 0) {
    next();
  } else if (!to.meta.allowedRoles.includes(userRole)) {
    let payload = {
      timeout: 3000,
      color: "error",
      message: "You don't have permissions for the requested operation.",
      code: 403,
    };
    Store.dispatch("setSnackBar", payload);
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
