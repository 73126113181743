import { StaffApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getAll() {
  const { data } = await StaffApi.getStaffList();
  return data;
}

export async function update(data) {
  const response = await StaffApi.editStaffUser(data);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function insert(data) {
  const response = await StaffApi.addStaffUser(data);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function remove(id) {
  const response = await StaffApi.deleteStaffUser(id);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function resetPassword(id) {
  const response = await StaffApi.resetStaffPassword(id);
  showSuccessSnackbar(response.status, response.data.message);
}
