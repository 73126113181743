import { WorkExperienceApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getByUserId(userId) {
  const { data } = await WorkExperienceApi.getUserWorkExperiences(userId);
  return data;
}

export async function insertSingle(data) {
  const response = await WorkExperienceApi.insertSingleWorkExperience(data);
  showSuccessSnackbar(response.status, "Work experience successfully added.");
  return response.data;
}

export async function insertMultiple(userId, data) {
  const response = await WorkExperienceApi.insertMultipleWorkExperiences(
    userId,
    data
  );
  showSuccessSnackbar(response.status, "Work experience successfully added.");
  return response.data;
}

export async function update(data) {
  const response = await WorkExperienceApi.updateSingleWorkExperience(data);
  showSuccessSnackbar(response.status, "Work experience successfully updated.");
  return response.data;
}

export async function remove(workExpId) {
  const response = await WorkExperienceApi.remove(workExpId);
  showSuccessSnackbar(response.status, "Work experience successfully deleted.");
  return response.data;
}
