<template>
  <v-navigation-drawer
    ref="jobs-drawer"
    app
    :expand-on-hover="isExpandOnHover"
    permanent
    :mini-variant="isExpandOnHover"
  >
    <template #prepend>
      <div
        class="px-2 d-flex justify-end align-center"
        :style="{
          height: APP_BAR_HEIGHT + 'px',
        }"
      >
        <div class="mx-auto">
          <span v-if="!isMini">
            <IasonLogoSvg
              width="100%"
              :height="APP_BAR_HEIGHT - 25"
            ></IasonLogoSvg
          ></span>
        </div>
        <v-btn icon :disabled="isMini" @click.stop="toggleFixedSidebar()">
          <v-icon>
            {{ isExpandOnHover ? "mdi-menu" : "mdi-dots-vertical" }}</v-icon
          >
        </v-btn>
      </div>
    </template>
    <v-list>
      <template v-for="navItem in navbarItems">
        <template v-if="navItem.items">
          <v-list-group
            :key="navItem.title"
            :value="false"
            :prepend-icon="navItem.icon"
            color="companyPrimary"
          >
            <template #activator>
              <v-list-item-title>{{ navItem.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="subItem in navItem.items"
              :key="navItem.title + '@' + subItem.title"
              :to="subItem.to"
              exact
              color="companyPrimary"
            >
              <v-list-item-icon>
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            :key="navItem.title"
            :to="navItem.to"
            exact
            color="companyPrimary"
          >
            <v-list-item-icon>
              <v-icon>{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ navItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
    <template #append>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar class="circle" color="companySecondary">
            {{ getUserInitials }}
          </v-list-item-avatar>
          <v-list-item-title>{{ getUserFullName }}</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import IasonLogoSvg from "@/components/svg/IasonLogoSvg.vue";
import { useSidebarLink } from "@/composables/use-sidebar-link";

export default {
  name: "TheSidebar",
  components: {
    IasonLogoSvg,
  },
  setup() {
    const { linkItems } = useSidebarLink();
    return { linkItems };
  },
  data: () => ({
    isExpandOnHover: true,
    isMini: true,
    APP_BAR_HEIGHT: 64,
  }),
  computed: {
    ...mapGetters("user", {
      getUserFullName: "getUserFullName",
      getUserInitials: "getUserInitials",
    }),
    navbarItems() {
      return this.linkItems.value;
    },
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs["jobs-drawer"].isMiniVariant;
      },
      (val) => {
        this.isMini = val;
      }
    );
  },
  methods: {
    ...mapActions("user", {
      setUserDefaultState: "setDefaultState",
    }),
    ...mapActions("test", {
      setTestDefaultState: "setDefaultState",
    }),
    toggleFixedSidebar() {
      this.isExpandOnHover = !this.isExpandOnHover;
    },
    async logout() {
      await this.$router.push({ name: "login" });
      this.setUserDefaultState();
      this.setTestDefaultState();
    },
  },
};
</script>
