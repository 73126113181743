import { Enum } from "../base/enum";

const statusEnum = new Enum(
  ["evaluation", "rejected", "withdrawn", "on_hold", "hired", "registration"],
  [0, 1, 2, 3, 4, 5],
  ["Evaluation", "Rejected", "Withdrawn", "On Hold", "Hired", "Registration"]
);

const howKnowIasonEnum = new Enum(
  ["other", "university", "iason_employees", "monster", "linkedIn", "mip"],
  [0, 1, 2, 3, 4, 5],
  [
    "Other",
    "University career services",
    "Company employees (current or former)",
    "Monster",
    "LinkedIn",
    "MIP",
  ]
);

const qualificationLevelEnum = new Enum(
  ["other", "diploma", "bachelor", "master", "doctorate"],
  [0, 1, 2, 3, 4],
  ["Other", "Diploma", "Bachelor", "Master", "Doctorate"]
);

const competenceCenterEnum = new Enum(
  ["none", "dev", "pmo", "crq", "fe&ds", "ba", "staff"],
  [0, 1, 2, 3, 4, 5, 6],
  ["None", "DEV", "PMO", "CRQ", "FE & DS", "BA", "STAFF"],
  [
    "None",
    "Developer",
    "Project Manager",
    "Credit Risk Quant",
    "Financial Engineer & Data Scientist",
    "Business Analyst",
    "Other",
  ]
);

const withdrawMotivationEnum = new Enum(
  ["ret", "offerrej"],
  [0, 1],
  ["Retired", "Offer Rejected"]
);

const incomingResumeStatusEnum = new Enum(
  ["pending", "accepted", "rejected"],
  [0, 1, 2],
  ["Pending", "Accepted", "Rejected"]
);

export {
  statusEnum,
  howKnowIasonEnum,
  qualificationLevelEnum,
  competenceCenterEnum,
  withdrawMotivationEnum,
  incomingResumeStatusEnum,
};
