import { axiosClient } from "../axios";

export function authorize(username, password) {
  const payload = {
    username,
    password,
  };
  return axiosClient.post("auth/login", payload);
}

export function checkAuthorization() {
  return axiosClient.get("auth/check-auth");
}

export function getOauthConfig() {
  return axiosClient.get("auth/oauth/config");
}

export function validateToken(token) {
  return axiosClient.post("auth/oauth/validate-token", token);
}

export function forgotPassword(userEmail) {
  return axiosClient.post(`auth/forgot-password?email=${userEmail}`);
}

export function forgotPasswordValidate(token, userEmail) {
  return axiosClient.post(
    `auth/forgot-password/validate?token=${token}&email=${userEmail}`
  );
}
