<template>
  <v-app>
    <TheSidebar v-if="isSidebarNeeded" />
    <TheHeader v-if="isHeaderNeeded" />
    <v-main>
      <v-container :fluid="enableFluid" :class="containerClass">
        <router-view></router-view>
      </v-container>
    </v-main>
    <TheSnackbar />
    <TheConfirmationDialog />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { useTitle } from "@vueuse/core";
import TheSidebar from "@/components/TheSidebar.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheSnackbar from "@/components/TheSnackbar.vue";
import TheConfirmationDialog from "@/components/TheConfirmationDialog.vue";

export default {
  name: "JobsApp",
  components: {
    TheSidebar,
    TheHeader,
    TheSnackbar,
    TheConfirmationDialog,
  },
  data() {
    return {
      tracker: null,
    };
  },
  computed: {
    ...mapGetters("user", {
      getUserId: "getUserId",
      isStaffUser: "isStaffUser",
    }),
    ...mapGetters("domain", {
      getLegalEntity: "getLegalEntity",
    }),
    isHeaderNeeded() {
      return this.$route.meta.header;
    },
    isSidebarNeeded() {
      return this.$route.meta.sidebar;
    },
    enableFluid() {
      return !this.$route.meta.disableFluid;
    },
    containerClass() {
      return {
        "fill-height": !this.$route.meta.disableFillHeight,
        "pa-0": !this.$route.meta.removeZeroPadding,
      };
    },
  },
  watch: {
    getUserId(newVal) {
      if (this.tracker && newVal !== null) {
        let userIdentifier = this.getUserIdentifier(newVal);
        this.tracker.setUserID(userIdentifier);
      }
    },
  },
  created() {
    // Keep it disabled for now
    // if (process.env.VUE_APP_ENVR === "prod") {
    //   this.tracker = new Tracker({
    //     projectKey: process.env.VUE_APP_OR_PROJECT_KEY,
    //   });
    //   if (this.tracker) {
    //     this.tracker.start();
    //     if (this.getUserId !== null) {
    //       let userIdentifier = this.getUserIdentifier(this.getUserId);
    //       this.tracker.setUserID(userIdentifier);
    //     }
    //   }
    // }
  },
  mounted() {
    const title = useTitle();
    title.value = this.getLegalEntity.label + " Careers";
  },
  destroyed() {
    if (this.tracker) this.tracker.stop();
  },
  methods: {
    getUserIdentifier(userId) {
      if (this.isStaffUser) {
        return "staff_" + userId;
      } else {
        return "user_" + userId;
      }
    },
  },
};
</script>

<style lang="scss">
@use "scss/main";
</style>
