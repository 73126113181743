import { LanguageSkillApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getByUserId(userId) {
  const { data } = await LanguageSkillApi.getUserLanguageSkill(userId);
  return data;
}

export async function insertSingle(data) {
  const response = await LanguageSkillApi.insert(data);
  showSuccessSnackbar(response.status, "Language Skill inserted successfully");
  return response.data;
}

export async function insertMultiple(userId, data) {
  const response = await LanguageSkillApi.insertMultipleLanguageSkill(
    userId,
    data
  );
  showSuccessSnackbar(response.status, "Language Skills inserted successfully");
  return response.data;
}

export async function update(data) {
  const response = await LanguageSkillApi.update(data);
  showSuccessSnackbar(response.status, "Language Skill updated successfully");
}

export async function remove(data) {
  const response = await LanguageSkillApi.remove(data);
  showSuccessSnackbar(response.status, "Language Skill deleted successfully");
}
