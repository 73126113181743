export default [
  {
    path: "/positions/external",
    name: "external-positions",
    component: () =>
      import(
        /* webpackChunkName: "external-positions" */ "@/views/job-positions/JobPositionsView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [],
      sidebar: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/positions/external/create",
    name: "create-position",
    component: () =>
      import(
        /* webpackChunkName: "external-positions" */ "@/views/job-positions/ManageSinglePositionView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
    },
  },
  {
    path: "/positions/external/:id(\\d+)",
    name: "external-position-single",
    component: () =>
      import(
        /* webpackChunkName: "external-positions" */ "@/views/job-positions/SingleJobPositionView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [],
      sidebar: true,
      removeZeroPadding: true,
      disableFillHeight: true,
    },
  },
  {
    path: "/positions/external/:id(\\d+)/edit",
    name: "edit-position",
    component: () =>
      import(
        /* webpackChunkName: "external-positions" */ "@/views/job-positions/ManageSinglePositionView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
    },
  },
];
