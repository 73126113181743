import { axiosClient } from "../axios";
import { getQuery } from "../helper";

export function getJobPositions(options = { organization: null }) {
  const query = getQuery(options);
  return axiosClient.get("open-positions", query);
}

export function getActiveJobPositions(options = { organization: null }) {
  const query = getQuery(options);
  return axiosClient.get("open-positions/public/active", query);
}

export function getSingleJobPosition(id) {
  return axiosClient.get(`open-positions/${id}`);
}

export function getPositionCandidates(id) {
  return axiosClient.get(`open-positions/${id}/candidates`);
}

export function deletePosition(id) {
  return axiosClient.delete(`open-positions/${id}/delete`);
}

export function updatePosition(id, data) {
  return axiosClient.post(`open-positions/${id}/update`, data);
}

export function applyToPosition(positionId, userId) {
  let data = { positionId: positionId, userId: userId };
  return axiosClient.post(`open-positions/apply`, data);
}

export function deleteUserCandidacy(positionId, userId) {
  return axiosClient.delete(
    `open-positions/${positionId}/users/${userId}/delete`
  );
}

export function addNewPosition(data) {
  return axiosClient.post(`open-positions/insert`, data);
}
