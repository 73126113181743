import { DomicileApi } from "@/api";

export async function getAllRegions() {
  const { data } = await DomicileApi.getAllRegions();
  return data;
}

export async function getProvinceForRegion(regionId) {
  const { data } = await DomicileApi.getProvinceForRegion(regionId);
  return data;
}

export async function getMunicipalityInProvince(provinceId) {
  const { data } = await DomicileApi.getMunicipalityInProvince(provinceId);
  return data;
}
