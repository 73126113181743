const getDialogState = () => {
  return {
    isOpen: false,
    data: {},
  };
};

const getDefaultState = () => {
  return {
    userGeneralInfo: getDialogState(),
    userItSkill: getDialogState(),
    userLanguage: getDialogState(),
    userWorkExperience: getDialogState(),
    userEducation: getDialogState(),
    userInterviewsNotes: getDialogState(),
    userCompensation: getDialogState(),
    userSuggestedPositionsAdd: getDialogState(),
    jobPositionInternalClose: getDialogState(),
    fileInput: getDialogState(),
    confirmation: getDialogState(),
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    OPEN_DIALOG(state, { dialogName, data }) {
      state[dialogName].isOpen = true;
      state[dialogName].data = data;
    },
    CLOSE_DIALOG(state, dialogName) {
      state[dialogName].isOpen = false;
      state[dialogName].data = {};
    },
    UPDATE_DIALOG_DATA(state, { dialogName, data }) {
      state[dialogName].data = data;
    },
  },
  actions: {
    openDialog({ commit }, payload) {
      commit("OPEN_DIALOG", payload);
    },
    closeDialog({ commit }, dialogName) {
      commit("CLOSE_DIALOG", dialogName);
    },
    updateDialogData({ commit }, payload) {
      commit("UPDATE_DIALOG_DATA", payload);
    },
  },
};
