import { Enum } from "../base/enum";

const quizTopicEnum = new Enum(
  [
    "verbal_logic",
    "numeric_logic",
    "finance",
    "financial_mathematics",
    "statistics",
    "stochastic_analysis",
    "probability",
    "econometrics",
    "programming",
    "project_management",
  ],
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [
    "Verbal Logic",
    "Numeric Logic",
    "Finance",
    "Financial Mathematics",
    "Statistics",
    "Stochastic Analysis",
    "Probability",
    "Econometrics",
    "Programming",
    "Project Management",
  ]
);

export { quizTopicEnum };
