export function bool2bit(bools) {
  let bits = 0;
  for (let i = 0; i < 5; i++) if (bools[i]) bits += 1 << i;
  return bits;
}
export function bit2bool(bits) {
  const bools = [];
  for (let i = 0; i < 5; i++) bools.push((bits & (1 << i)) > 0);
  return bools;
}
