import { ContractualFrameworkService } from "@/services";

const getDefaultState = () => {
  return {
    items: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getContractualFrameworks(state) {
      return state.items;
    },
  },
  mutations: {
    SET_CONTRACTUAL_FRAMEWORK_LIST(state, payload) {
      state.items = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async fetchContractualFrameworkList({ commit, getters }) {
      if (getters.getContractualFrameworks == null) {
        const data = await ContractualFrameworkService.getAll();
        let payload = data.map((el) => {
          return { value: el.id, text: el.frameworkName };
        });
        commit("SET_CONTRACTUAL_FRAMEWORK_LIST", payload);
      }
      return getters.getContractualFrameworks;
    },
    async fetch({ commit }) {
      const data = await ContractualFrameworkService.getAll();
      const payload = data.map((el) => {
        return { value: el.id, text: el.frameworkName };
      });
      commit("SET_CONTRACTUAL_FRAMEWORK_LIST", payload);
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
