export default [
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/users/UsersView.vue"),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2, 3],
      sidebar: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/users/:id(\\d+)",
    name: "single-user",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/users/SingleUserView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/users/:id(\\d+)/tests",
    name: "tests-user",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/user-tests/UserTestsView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [0],
      sidebar: true,
    },
  },
  {
    path: "/users/:id(\\d+)/tests/:roleId(\\d+)",
    name: "single-test-user",
    component: () =>
      import(
        /* webpackChunkName: "tests" */ "@/views/user-tests/SingleTestView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [0],
    },
  },
  {
    path: "/users/search",
    name: "search-user",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/users/SearchUserView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2, 3],
      sidebar: true,
    },
  },
  {
    path: "/users/create",
    name: "create-user",
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/views/users/CreateUserView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/users/cc",
    name: "users-competence-center",
    component: () =>
      import(
        /* webpackChunkName: "competence-center" */ "@/views/users/CompetenceCenterView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [3, 4],
      sidebar: true,
    },
  },
];
