import { TestApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getByUserId(userId) {
  const { data } = await TestApi.geTestsByUserId(userId);
  return data;
}

export async function checkUserTest(userId, testId) {
  const { data } = await TestApi.checkUserTest(userId, testId);
  return data;
}

export async function checkUserTestByRoleId(userId, roleId) {
  const { data } = await TestApi.checkUserTestByRoleId(userId, roleId);
  return data;
}

export async function getLogicTest() {
  const { data } = await TestApi.getLogicTest();
  return data;
}

export async function getTestByRole(roleId) {
  const { data } = await TestApi.getTestByRole(roleId);
  return data;
}

export async function submit(testId, data) {
  const response = await TestApi.submitTest(testId, data);
  showSuccessSnackbar(response.status, response.data.message);
  return response;
}
