import { axiosClient } from "../axios";

export function getUserLanguageSkill(id) {
  return axiosClient.get(`language-skills/${id}`);
}

export function insert(data) {
  return axiosClient.post("language-skills/insert", data);
}

export function insertMultipleLanguageSkill(userId, data) {
  return axiosClient.post(`language-skills/insert/${userId}/multiple`, data);
}

export function update(data) {
  return axiosClient.post(
    `language-skills/${data.userId}/${data.language}/update`,
    data
  );
}

export function remove({ userId, language }) {
  return axiosClient.delete(`language-skills/${userId}/${language}/delete`);
}
