export default [
  {
    path: "/positions/internal",
    name: "internal-positions",
    component: () =>
      import(
        /* webpackChunkName: "internal-positions" */ "@/views/job-positions/internal/JobPositionsInternalView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2, 3],
      sidebar: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/positions/internal/create",
    name: "internal-position-create",
    component: () =>
      import(
        /* webpackChunkName: "internal-positions" */ "@/views/job-positions/internal/JobPositionsInternalManage.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2, 3],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/positions/internal/:id(\\d+)",
    name: "internal-position-single",
    component: () =>
      import(
        /* webpackChunkName: "internal-positions" */ "@/views/job-positions/internal/JobPositionsInternalSingle.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2, 3],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/positions/internal/:id(\\d+)/edit",
    name: "internal-position-edit",
    component: () =>
      import(
        /* webpackChunkName: "internal-positions" */ "@/views/job-positions/internal/JobPositionsInternalManage.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2, 3],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
];
