<template>
  <v-btn v-bind="{ ...baseAttrs, ...$attrs }" v-on="$listeners">
    <slot>
      <v-icon left>mdi-content-save</v-icon>
      Save
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButtonSave",
  inheritAttrs: false,
  computed: {
    baseAttrs() {
      return {
        color: "success",
      };
    },
  },
};
</script>
