export default [
  {
    path: "/",
    name: "home",
    redirect: { name: "login" },
    meta: { requiresAuth: false, allowedRoles: [] },
  },
  {
    path: "/legal/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/public/PrivacyPolicyView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
  {
    path: "/legal/careers-privacy-policy",
    name: "privacy-policy-careers",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/public/CareersPrivacyPolicyView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [], header: true },
  },
];
