import { TestService } from "@/services";
import { bit2bool } from "@/utils/quiz";

const getDefaultState = () => {
  return {
    testId: null,
    testStep: null,
    testStartTs: null,
    test: [],
    userAnswer: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getUserTest(state) {
      return state.test;
    },
    getUserAnswer(state) {
      return state.userAnswer;
    },
    getTestId(state) {
      return state.testId;
    },
    getTestStep(state) {
      return state.testStep ? state.testStep : 1;
    },
    getTestStartTs(state) {
      return state.testStartTs ? state.testStartTs : 1;
    },
  },
  mutations: {
    SET_USER_TEST(state, payload) {
      Object.assign(state.test, payload);
    },
    SET_USER_ANSWER(state, payload) {
      Object.assign(state.userAnswer, payload);
    },
    SET_TEST_ID(state, payload) {
      state.testId = payload;
    },
    SET_TEST_STEP(state, payload) {
      state.testStep = payload;
    },
    SET_TEST_START_TS(state, payload) {
      state.testStartTs = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    setUserAnswer({ commit }, payload) {
      commit("SET_USER_ANSWER", payload);
    },
    setTestId({ commit }, payload) {
      commit("SET_TEST_ID", payload);
    },
    setTestStep({ commit }, payload) {
      commit("SET_TEST_STEP", payload);
    },
    setTestStartTs({ commit }, payload) {
      commit("SET_TEST_START_TS", payload);
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
    async initUserTest({ commit, getters, state }, payload) {
      if (state.testId) {
        const isTestPresentInDb = TestService.checkUserTest(
          payload.userId,
          state.testId
        );

        if (getters.getUserTest.length !== 0 && isTestPresentInDb)
          return getters.getUserTest;
      } else {
        const data = await TestService.checkUserTestByRoleId(
          payload.userId,
          payload.roleId
        );
        if (data) {
          commit("SET_USER_TEST", data.quizzes);
          commit("SET_TEST_ID", data.id);
          commit("SET_TEST_STEP", 1);
          commit("SET_TEST_START_TS", data.startTs);

          return getters.getUserTest;
        }
      }
      const data = await (payload.roleId === 0
        ? TestService.getLogicTest()
        : TestService.getTestByRole(payload.roleId));
      commit("SET_USER_TEST", data);
      commit("SET_TEST_ID", data[0].testId);
      commit("SET_TEST_STEP", 1);
      commit("SET_TEST_START_TS", data[0].startTs);

      return getters.getUserTest;
    },
    fetchUserAnswer({ commit, getters, state }) {
      if (Object.keys(getters.getUserAnswer).length !== 0)
        return getters.getUserAnswer;

      let payload = {};

      state.test.forEach((el, i) => {
        payload[`quiz${i}`] = bit2bool(el.answer);
      });

      commit("SET_USER_ANSWER", payload);

      return getters.getUserAnswer;
    },
  },
};
