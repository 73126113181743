import { axiosClient } from "../axios";

export function insertRequestedCompensation(data) {
  return axiosClient.post(`requested-compensations/insert`, data);
}

export function updateRequestedCompensation(compensationId, data) {
  return axiosClient.post(
    `requested-compensations/${compensationId}/update`,
    data
  );
}

export function deleteRequestedCompensation(compensationId) {
  return axiosClient.delete(`requested-compensations/${compensationId}/delete`);
}
