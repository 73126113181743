import { axiosClient } from "../axios";

export function geTestsByUserId(userId) {
  return axiosClient.get(`tests/${userId}`);
}

export function checkUserTest(userId, testId) {
  return axiosClient.get(`tests/${userId}/check/${testId}`);
}

export function checkUserTestByRoleId(userId, roleId) {
  return axiosClient.get(`tests/${userId}/check-by-role/${roleId}`);
}

export function getLogicTest() {
  return axiosClient.get(`tests/logic`);
}

export function getTestByRole(roleId) {
  return axiosClient.get(`tests/role/${roleId}`);
}

export function submitTest(testId, data) {
  return axiosClient.post(`tests/submit/${testId}`, data);
}
