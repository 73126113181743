import { QuizApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getAll(searchArray) {
  const { data } = await QuizApi.getQuizList(searchArray);
  return data;
}

export async function get(id) {
  const { data } = await QuizApi.getSingleQuiz(id);
  return data;
}

export async function insert(payload) {
  const response = await QuizApi.addNewQuiz(payload);
  showSuccessSnackbar(response.status, "Quiz inserted successfully");
  return response.data;
}

export async function update(id, payload) {
  const response = await QuizApi.updateQuiz(id, payload);
  showSuccessSnackbar(response.status, "Quiz updated successfully");
  return response.data;
}
