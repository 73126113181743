import { RegistrationApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";
import Router from "@/router";
import Store from "@/store";
import { mapRole2Cc } from "@/utils/user";

export async function register(data, positionId, roleId) {
  if (roleId) data.competenceCenter = mapRole2Cc(roleId);

  positionId = positionId ? positionId : "";
  roleId = roleId ? roleId : "";

  await RegistrationApi.registerUser(data, positionId, roleId).then(() => {
    Router.push({ name: "registration-thank-you" });
  });
}

export async function validateEmail(token) {
  const response = await RegistrationApi.validateEmail(token);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function renewToken(token) {
  const response = await RegistrationApi.renewToken(token);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function finalizeRegistration(userId, data) {
  const response = await RegistrationApi.finalizeRegistration(userId, data);
  showSuccessSnackbar(response.status, response.data.message);
  Store.dispatch(
    "user/setRegistrationCompletedTs",
    response.data.regCompletedTs
  );
  Router.push({
    name: "dashboard",
  });
}
