import { QualificationApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getByUserId(userId) {
  const { data } = await QualificationApi.getUserQualifications(userId);
  return data;
}

export async function insertSingle(payload) {
  const response = await QualificationApi.insertSingle(payload);
  showSuccessSnackbar(response.status, "Education inserted successfully");
}

export async function insertMultiple(userId, payload) {
  const response = await QualificationApi.insertMultipleQualifications(
    userId,
    payload
  );
  showSuccessSnackbar(response.status, "Education inserted successfully");
}

export async function update(payload) {
  const response = await QualificationApi.update(payload);
  showSuccessSnackbar(response.status, "Education updated successfully");
}

export async function remove(id) {
  const response = await QualificationApi.remove(id);
  showSuccessSnackbar(response.status, "Education deleted successfully");
}
