import Store from "@/store";
import { getLabel } from "@/utils/enums";

export function parseStatus(item) {
  if (item.status === 2 && item.withdrawnMotivation !== undefined) {
    return (
      getLabel("userStatus", item.status) +
      " - " +
      getLabel("withdrawMotivation", item.withdrawnMotivation)
    );
  } else {
    return getLabel("userStatus", item.status);
  }
}

export function itSkillChipText(skill) {
  const itSkill = getLabel("itSkill", skill["itSkillId"] ?? skill["topic"]);
  const level = getLabel("itLevel", skill["levelId"] ?? skill["level"]);
  return `${itSkill} - ${level}`;
}

export function mapRole2Cc(roleCode) {
  const roleToCcMap = {
    2: 5, // Business Analyst -> ba
    3: 4, // Financial Engineer -> fe&ds
    5: 4, // Data Analyst -> fe&ds
    10: 4, // Data Scientist -> fe&ds
    4: 3, // Statistical Analyst -> crq
    6: 6, // Marketing Analyst -> staff
    7: 2, // Project Manager -> pmo
    8: 1, // Web Developer -> dev
    9: 1, // Software Developer -> dev
  };

  return roleToCcMap[roleCode] || 0; // 0: None
}

export function sameCcOrAdmin(competenceCenterId) {
  const isAdmin = Store.getters["user/isAdmin"];
  return isAdmin || sameCompetenceCenter(competenceCenterId);
}

export function sameCcOrManager(competenceCenterId) {
  const isManager = Store.getters["user/isManager"];
  return isManager || sameCompetenceCenter(competenceCenterId);
}

export function sameCompetenceCenter(competenceCenterId) {
  const userCc = Store.getters["user/getUserCompetenceCenter"];
  return userCc == competenceCenterId;
}
