import { axiosClient } from "../axios";

export function getAllRegions() {
  return axiosClient.get(`regions`);
}

export function getProvinceForRegion(regionId) {
  return axiosClient.get(`provinces?regionId=${regionId}`);
}

export function getMunicipalityInProvince(provinceId) {
  return axiosClient.get(`municipalities?provinceId=${provinceId}`);
}
