import usersRoutes from "./users";
import positionsExternalRoutes from "./positions-external";
import positionsInternalRoutes from "./positions-internal";
import questionsRoutes from "./questions";
import loginRoutes from "./login";
import registrationRoutes from "./registration";
import publicRoutes from "./public";

export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "@/views/dashboard/JobsDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [],
      sidebar: true,
      disableFillHeight: true,
      removeZeroPadding: true,
    },
  },
  {
    path: "/staff",
    name: "staff",
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/StaffView.vue"),
    meta: {
      requiresAuth: true,
      requiresRegComplete: true,
      allowedRoles: [1, 2],
      sidebar: true,
      removeZeroPadding: true,
    },
  },
  ...publicRoutes,
  ...registrationRoutes,
  ...loginRoutes,
  ...usersRoutes,
  ...positionsExternalRoutes,
  ...positionsInternalRoutes,
  ...questionsRoutes,
];
