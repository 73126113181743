import { RoleCandidacyApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getByUserId(userId) {
  const { data } = await RoleCandidacyApi.getRoleCandidacyByUser(userId);
  return data;
}

export async function enableTests(id) {
  const response = await RoleCandidacyApi.enableTests(id);
  showSuccessSnackbar(response.status, "Tests enabled");
}

export async function insert(data) {
  const response = await RoleCandidacyApi.insertRoleCandidacy(data);
  showSuccessSnackbar(response.status, "Role candidacy inserted");
}

export async function remove(roleId) {
  const response = await RoleCandidacyApi.deleteRoleCandidacy(roleId);
  showSuccessSnackbar(response.status, "Role candidacy reset");
}
