import { Enum } from "../base/enum";

const skillEnum = new Enum(
  [
    "c",
    "c_Plus_Plus",
    "c_Sharp",
    "f_Sharp",
    "java",
    "javascript",
    "python",
    "matlab",
    "go",
    "stata",
    "r",
    "cuda",
    "php",
    "sql",
    "xml",
    "html",
    "css",
    "sas",
    "angular",
    "git",
    "redmine",
    "msOffice",
    "libreOffice",
    "powershell",
    "bash",
    "pySpark",
    "rust",
    "typescript",
    "scala",
    "docker",
    "kubernetes",
    "jenkins",
    "terraform",
    "ruby",
    "perl",
    "react",
    "vue",
  ],
  [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
  ],
  [
    "C",
    "C++",
    "C#",
    "F#",
    "Java",
    "JavaScript",
    "Python",
    "Matlab",
    "Go",
    "Stata",
    "R",
    "Cuda",
    "Php",
    "Sql",
    "Xml",
    "Html",
    "CSS",
    "Sas",
    "Angular",
    "Git",
    "Redmine",
    "MS Office",
    "LibreOffice",
    "Powershell",
    "Bash",
    "PySpark",
    "Rust",
    "TypeScript",
    "Scala",
    "Docker",
    "Kubernetes",
    "Jenkins",
    "Terraform",
    "Ruby",
    "Perl",
    "React",
    "Vue.js",
  ]
);

const levelEnum = new Enum(
  ["none", "basic", "discrete", "good", "excellent"],
  [0, 1, 2, 3, 4],
  ["None", "Basic", "Discrete", "Good", "Excellent"]
);

export { skillEnum, levelEnum };
