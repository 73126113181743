import { RtmService } from "@/services";

const getDefaultState = () => {
  return {
    allSbus: null,
    activeSbus: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getAllSbus(state) {
      return state.allSbus;
    },
    getActiveSbus(state) {
      return state.activeSbus;
    },
  },
  mutations: {
    SET_ALL_SBUS(state, payload) {
      state.allSbus = payload;
    },
    SET_ACTIVE_SBUS(state, payload) {
      state.activeSbus = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async fetchAllSbus({ commit, getters }) {
      if (getters.getAllSbus == null) {
        const data = await RtmService.getSbus({ type: "all" });
        commit("SET_ALL_SBUS", data);
      }
      return getters.getAllSbus;
    },
    async fetchActiveSbus({ commit, getters }) {
      if (getters.getActiveSbus == null) {
        const data = await RtmService.getSbus({ type: "active" });
        commit("SET_ACTIVE_SBUS", data);
      }
      return getters.getActiveSbus;
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
