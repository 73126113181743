import { axiosClient } from "../axios";

export function getQuizList(searchArray) {
  if (!searchArray) return axiosClient.get("quizzes");

  const params = new URLSearchParams();
  for (const item of searchArray) {
    params.append("topics", item);
  }
  const request = {
    params: params,
  };
  return axiosClient.get("quizzes", request);
}

export function getSingleQuiz(id) {
  return axiosClient.get(`quizzes/${id}`);
}

export function updateQuiz(id, data) {
  return axiosClient.post(`quizzes/${id}/update`, data);
}

export function addNewQuiz(data) {
  return axiosClient.post(`quizzes/insert`, data);
}
