import { OrganizationService } from "@/services";

const getDefaultState = () => {
  return {
    items: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getOrganizationList(state) {
      return state.items;
    },
  },
  mutations: {
    SET_ORGANIZATION_LIST(state, payload) {
      state.items = payload;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    async fetchOrganizations({ commit, getters }) {
      if (getters.getOrganizationList == null) {
        const data = await OrganizationService.getAll();
        let payload = data.map((el) => {
          return { value: el.id, text: el.name };
        });
        commit("SET_ORGANIZATION_LIST", payload);
      }
      return getters.getOrganizationList;
    },
    async fetch({ commit }) {
      const data = await OrganizationService.getAll();
      const payload = data.map((el) => {
        return { value: el.id, text: el.name };
      });
      commit("SET_ORGANIZATION_LIST", payload);
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
