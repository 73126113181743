import { RequestedCompensationApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function insert(data) {
  const response = await RequestedCompensationApi.insertRequestedCompensation(
    data
  );
  showSuccessSnackbar(response.status, "Requested compensation inserted");
}

export async function update(compensationId, data) {
  const response = await RequestedCompensationApi.updateRequestedCompensation(
    compensationId,
    data
  );
  showSuccessSnackbar(response.status, "Requested compensation updated");
}

export async function remove(compensationId) {
  const response = await RequestedCompensationApi.deleteRequestedCompensation(
    compensationId
  );
  showSuccessSnackbar(response.status, "Requested compensation deleted");
}
