import { NoteApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function insert(payload) {
  const response = await NoteApi.insertNote(payload);
  showSuccessSnackbar(response.status, "Note inserted successfully");
}

export async function update(payload) {
  const response = await NoteApi.updatetNote(payload);
  showSuccessSnackbar(response.status, "Note updated successfully");
}

export async function remove(id) {
  const response = await NoteApi.deleteNote(id);
  showSuccessSnackbar(response.status, "Note deleted successfully");
}
