import { ItSkillApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getByUserId(id) {
  const { data } = await ItSkillApi.getUserItSkills(id);
  return data;
}

export async function insertSingle(data) {
  const response = await ItSkillApi.insertSingleItSkill(data);
  showSuccessSnackbar(response.status, "It skill inserted successfully");
}

export async function insertMultiple(userId, data) {
  const response = await ItSkillApi.insertMultipleItSkills(userId, data);
  showSuccessSnackbar(response.status, "It skills inserted successfully");
}

export async function update(data) {
  const response = await ItSkillApi.updateSingleItSkill(data);
  showSuccessSnackbar(response.status, "It skill updated successfully");
}

export async function remove(data) {
  const response = await ItSkillApi.remove(data);
  showSuccessSnackbar(response.status, "It skill deleted successfully");
}
