const getDefaultState = () => {
  return {
    id: null,
    rtmId: null,
    rtmCode: null,
    rtmSbuIds: null,
    name: null,
    surname: null,
    email: null,
    role: null,
    competenceCenter: null,
    organizationId: null,
    registrationCompletedTs: null,
    token: null,
    oauthToken: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isAuthenticated: (state) => {
      return !!state.token;
    },
    isRegistrationCompleted: (state, getters) => {
      if (getters.isStaffUser) return true;
      return state.registrationCompletedTs != null;
    },
    getUserId: (state) => {
      return state.id;
    },
    getUserRtmId: (state) => {
      return state.rtmId;
    },
    getUserRtmCode: (state) => {
      return state.rtmCode;
    },
    getUserRtmSbuIds: (state) => {
      return state.rtmSbuIds;
    },
    getUserRole: (state) => {
      return state.role;
    },
    getUserCompetenceCenter: (state) => {
      return state.competenceCenter;
    },
    getOrganizationId: (state) => {
      return state.organizationId;
    },
    isUser: (state) => {
      return state.role === 0;
    },
    isRoot: (state) => {
      return state.role === 1;
    },
    isAdmin: (state) => {
      return state.role > 0 && state.role <= 2;
    },
    isManager: (state) => {
      return state.role === 3;
    },
    isInterviewer: (state) => {
      return state.role === 4;
    },
    isStaffUser: (state) => {
      return state.role && state.role !== 0;
    },
    getUserToken: (state) => {
      return state.token;
    },
    getOauthToken(state) {
      return state.oauthToken;
    },
    getUserFullName: (state) => {
      return `${state.name} ${state.surname}`;
    },
    getUserName: (state) => {
      return `${state.name}`;
    },
    getUserEmail: (state) => {
      return `${state.email}`;
    },
    getUserInitials: (state) => {
      if (state.name != null && state.surname != null) {
        return state.name.substring(0, 1) + state.surname.substring(0, 1);
      }
      return null;
    },
  },
  mutations: {
    SET_ID(state, id) {
      state.id = id;
    },
    SET_RTM_ID(state, rtmId) {
      state.rtmId = rtmId;
    },
    SET_RTM_CODE(state, rtmCode) {
      state.rtmCode = rtmCode;
    },
    SET_RTM_SBU_IDS(state, rtmSbuIds) {
      state.rtmSbuIds = rtmSbuIds;
    },
    SET_NAME(state, name) {
      state.name = name;
    },
    SET_SURNAME(state, surname) {
      state.surname = surname;
    },
    SET_EMAIL(state, email) {
      state.email = email;
    },
    SET_ROLE(state, role) {
      state.role = role;
    },
    SET_COMPETENCE_CENTER(state, competenceCenter) {
      state.competenceCenter = competenceCenter;
    },
    SET_ORGANIZATION_ID(state, organizationId) {
      state.organizationId = organizationId;
    },
    SET_REGISTRATION_COMPLETED_TS(state, payload) {
      state.registrationCompletedTs = payload;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_OAUTH_TOKEN(state, oauthToken) {
      state.oauthToken = oauthToken;
    },
    SET_DEFAULT_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    initUserDetails({ commit }, payload) {
      const token = payload.token;
      const user = payload.user;
      commit("SET_ID", user.id);
      if (user.adminInfo) {
        commit("SET_RTM_ID", user.adminInfo.id);
        commit("SET_RTM_CODE", user.adminInfo.code);
        commit("SET_RTM_SBU_IDS", user.adminInfo.sbuIds);
      }
      commit("SET_NAME", user.name);
      commit("SET_SURNAME", user.surname);
      commit("SET_EMAIL", user.email);
      commit("SET_ROLE", user.role);
      commit("SET_COMPETENCE_CENTER", user.competenceCenter);
      commit("SET_ORGANIZATION_ID", user.organizationId);
      commit(
        "SET_REGISTRATION_COMPLETED_TS",
        user.registrationCompletedTs || null
      );
      commit("SET_TOKEN", token);
    },
    setId({ commit }, payload) {
      commit("SET_ID", payload);
    },
    setName({ commit }, payload) {
      commit("SET_NAME", payload);
    },
    setSurname({ commit }, payload) {
      commit("SET_SURNAME", payload);
    },
    setEmail({ commit }, payload) {
      commit("SET_EMAIL", payload);
    },
    setRole({ commit }, payload) {
      commit("SET_ROLE", payload);
    },
    setCompetenceCenter({ commit }, payload) {
      commit("SET_COMPETENCE_CENTER", payload);
    },

    setRegistrationCompletedTs({ commit }, payload) {
      commit("SET_REGISTRATION_COMPLETED_TS", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setOauthToken({ commit }, payload) {
      commit("SET_OAUTH_TOKEN", payload);
    },
    setDefaultState({ commit }) {
      commit("SET_DEFAULT_STATE");
    },
  },
};
