import { Enum } from "../base/enum";

const locationEnum = new Enum(
  ["milan", "piacenza", "london"],
  [0, 1, 2],
  ["Milan, IT", "Piacenza, IT", "London, UK"]
);

const priorityEnum = new Enum(
  ["high", "medium", "low"],
  [1, 2, 3],
  ["High", "Medium", "Low"]
);

const closeReasonEnum = new Enum(
  ["candidate_found", "candidate_not_found", "not_needed_anymore"],
  [1, 2, 3],
  ["Candidate Found", "Candidate Not Found", "Not Needed Anymore"]
);

export { locationEnum, priorityEnum, closeReasonEnum };
