import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import UserModule from "./modules/user";
import TestModule from "./modules/test";
import InstitutesModule from "./modules/institutes";
import ContractualFrameworksModule from "./modules/contractual-frameworks";
import DomainModule from "./modules/domain";
import OrganizationModule from "./modules/organization";
import DialogModule from "./modules/dialog";
import CandidateModule from "./modules/candidate";
import SbuModule from "./modules/sbu";

Vue.use(Vuex);

const storeModules = {
  user: UserModule,
  test: TestModule,
  institutes: InstitutesModule,
  contractualFrameworks: ContractualFrameworksModule,
  domain: DomainModule,
  organization: OrganizationModule,
  dialog: DialogModule,
  candidate: CandidateModule,
  sbu: SbuModule,
};

const storeOptions = {
  state: {
    snackbar: {
      active: false,
      timeout: undefined,
      color: undefined,
      message: undefined,
      code: undefined,
    },
  },
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
  },
  actions: {
    setSnackBar({ commit }, payload) {
      commit("SET_SNACKBAR", {
        active: true,
        timeout: payload.timeout,
        color: payload.color,
        message: payload.message,
        code: payload.code,
      });
    },
  },
  plugins: [
    createPersistedState({
      paths: ["user", "test"],
    }),
  ],
  modules: storeModules,
};

export default new Vuex.Store(storeOptions);
