export default [
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/LoginView.vue"),
    meta: { requiresAuth: false, allowedRoles: [] },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/login/ForgotPasswordView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [] },
  },
  {
    path: "/forgot-password/validate",
    name: "forgot-password-validate",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/login/ConfirmForgotPasswordView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [] },
  },
  {
    path: "/admin/login",
    name: "login-admin",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "@/views/login/LoginAdminView.vue"
      ),
    meta: { requiresAuth: false, allowedRoles: [] },
  },
];
