import { JobPositionApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function getAll(options) {
  const { data } = await JobPositionApi.getJobPositions(options);
  return data;
}

export async function getActive(options) {
  const { data } = await JobPositionApi.getActiveJobPositions(options);
  return data;
}

export async function get(id) {
  const { data } = await JobPositionApi.getSingleJobPosition(id);
  return data;
}

export async function getCandidates(id) {
  const { data } = await JobPositionApi.getPositionCandidates(id);
  return data;
}

export async function insert(data) {
  const response = await JobPositionApi.addNewPosition(data);
  showSuccessSnackbar(response.status, "Job Position inserted successfully");
  return response.data;
}

export async function update(id, data) {
  const response = await JobPositionApi.updatePosition(id, data);
  showSuccessSnackbar(response.status, "Job Position updated successfully");
}

export async function remove(id) {
  const response = await JobPositionApi.deletePosition(id);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function apply(positionId, userId) {
  const response = await JobPositionApi.applyToPosition(positionId, userId);
  showSuccessSnackbar(response.status, response.data.message);
}

export async function removeApplication(positionId, userId) {
  const response = await JobPositionApi.deleteUserCandidacy(positionId, userId);
  showSuccessSnackbar(response.status, response.data.message);
}
