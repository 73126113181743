import { InterviewApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";

export async function insert(payload) {
  const response = await InterviewApi.insertInterview(payload);
  showSuccessSnackbar(response.status, "Interview inserted successfully");
}

export async function update(payload) {
  const response = await InterviewApi.updateInterview(payload);
  showSuccessSnackbar(response.status, "Interview updated successfully");
}

export async function remove(id) {
  const response = await InterviewApi.deleteInterview(id);
  showSuccessSnackbar(response.status, "Interview deleted successfully");
}
