import { axiosClient } from "../axios";

export function getUsers() {
  return axiosClient.get("users");
}

export function getUsersByCc(competenceCenterId) {
  return axiosClient.get(`users/cc/${competenceCenterId}`);
}

export function getSingleUser(id) {
  return axiosClient.get(`users/${id}`);
}

export function createUser(data) {
  return axiosClient.post(`users/create`, data);
}

export function updateUser(id, data) {
  return axiosClient.post(`users/${id}/update`, data);
}

export function deleteUser(id) {
  return axiosClient.delete(`users/${id}/delete`);
}

export function resetUserPassword(id) {
  return axiosClient.post(`users/${id}/reset-password`);
}

export function getUserResumePreSignedUrl(id) {
  return axiosClient.get(`users/${id}/cv/pre-signed-url`);
}

export function uploadUserResume(id, data) {
  return axiosClient.post(`users/${id}/cv/upload`, data);
}

export function getUserInterviews(id) {
  return axiosClient.get(`users/${id}/interviews`);
}

export function getUserNotes(id) {
  return axiosClient.get(`users/${id}/notes`);
}

export function getUserFullProfile(id) {
  return axiosClient.get(`users/${id}/details`);
}

export function deleteUserTests(id) {
  return axiosClient.delete(`users/${id}/delete-tests`);
}

export function deleteUserGeneralTest(id) {
  return axiosClient.delete(`users/${id}/delete-gr-test`);
}

export function deleteUserSingleRoleTests(id, roleId) {
  return axiosClient.delete(`users/${id}/delete-role-tests/${roleId}`);
}

export function updateUserStatus(id, data) {
  return axiosClient.post(`users/${id}/update-status`, data);
}

export function validateUserEmail(id) {
  return axiosClient.post(`users/${id}/validate-email`);
}

export function enableUser(id) {
  return axiosClient.post(`users/${id}/enable`);
}

export function advancedUserSearch(data) {
  return axiosClient.get(`users/search?${data}`);
}

export function exportUserSearch(data) {
  return axiosClient.post(`export/search/xlsx`, data);
}
