import { AuthApi } from "@/api";
import { showSuccessSnackbar } from "@/utils/snackbar";
import Router from "@/router";

export async function authorize(username, password) {
  const { data } = await AuthApi.authorize(username, password);
  return data;
}

export async function checkAuthorization() {
  const { data } = await AuthApi.checkAuthorization();
  return data;
}

export async function getOauthConfig() {
  const { data } = await AuthApi.getOauthConfig();
  return data;
}

export async function validateToken(token) {
  const { data } = await AuthApi.validateToken(token);
  return data;
}

export async function forgotPassword(userEmail) {
  const response = await AuthApi.forgotPassword(userEmail);
  showSuccessSnackbar(response.status, response.data.message);
  Router.push("login");
}

export async function forgotPasswordValidate(token, userEmail) {
  const { data } = await AuthApi.forgotPasswordValidate(token, userEmail);
  return data;
}
