import { axiosClient } from "../axios";
import { getQuery } from "../helper";

export function getUserDashboardData(userId) {
  return axiosClient.get(`dashboard/user/${userId}`);
}

export function getManagerLatestTests(
  competenceCenterId,
  options = { range: 30 }
) {
  const query = getQuery(options);
  return axiosClient.get(
    `dashboard/manager/${competenceCenterId}/tests/latest`,
    query
  );
}

export function getManagerLatestInterviews(
  competenceCenterId,
  options = { range: 30 }
) {
  const query = getQuery(options);
  return axiosClient.get(
    `dashboard/manager/${competenceCenterId}/interviews/latest`,
    query
  );
}

export function getManagerMissingInterviews(
  competenceCenterId,
  options = { range: 30 }
) {
  const query = getQuery(options);
  return axiosClient.get(
    `dashboard/manager/${competenceCenterId}/interviews/missing`,
    query
  );
}

export function getAdminLatestTests(options = { range: 30 }) {
  const query = getQuery(options);
  return axiosClient.get(`dashboard/admin/tests/latest`, query);
}

export function getAdminLatestInterviews(options = { range: 30 }) {
  const query = getQuery(options);
  return axiosClient.get(`dashboard/admin/interviews/latest`, query);
}

export function getAdminMissingInterviews(options = { range: 30 }) {
  const query = getQuery(options);
  return axiosClient.get(`dashboard/admin/interviews/missing`, query);
}
