<template>
  <v-dialog v-model="isOpen" persistent max-width="600px">
    <v-card>
      <v-card-title :class="[color, textColor]">{{ title }}</v-card-title>
      <v-card-text class="text-body-1 text--primary pt-5">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <BaseButtonBack @click="onClose" />
        <BaseButtonSave
          text
          :color="color"
          :loading="loading"
          @click="onConfirm"
        >
          Confirm
        </BaseButtonSave>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import BaseButtonBack from "@/components/base/button/BaseButtonBack.vue";
import BaseButtonSave from "@/components/base/button/BaseButtonSave.vue";

export default {
  name: "TheConfirmationDialog",
  components: {
    BaseButtonBack,
    BaseButtonSave,
  },
  emits: ["input", "confirm-dialog"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    store() {
      return this.$store.state.dialog.confirmation;
    },
    isOpen() {
      return this.store.isOpen;
    },
    dialogData() {
      return this.store.data;
    },
    title() {
      return this.dialogData.title;
    },
    text() {
      return this.dialogData.text;
    },
    variant() {
      return this.dialogData.variant;
    },
    color() {
      switch (this.variant) {
        case "success":
          return "success";
        case "error":
          return "red darken-1";
        case "warning":
          return "warning";
        case "info":
          return "info";
        default:
          return "info";
      }
    },
    textColor() {
      if (["error", "info"].includes(this.variant)) return "white--text";
      return "";
    },
  },
  methods: {
    ...mapActions("dialog", {
      closeDialog: "closeDialog",
    }),
    onClose() {
      this.closeDialog("confirmation");
    },
    async onConfirm() {
      this.loading = true;
      await this.dialogData.callback();
      this.loading = false;
      this.onClose();
    },
  },
};
</script>
